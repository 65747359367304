$(document).ready(function() {
	orientaionFix.init();
	getHelpers.init();
	getSlideshow.init();
	getNavigation.init();
	getHeaderColor.init();
	scrollToTop.init();
	getLoadMorePHP.init();
	detailPageAnimation.init();

    if($("body").attr("data-article-id") == 1) {
        slideShow.init();
        backgroundMedia.init();
    }

	if(location.hash === '#s5') {
		$.fn.fullpage.moveTo(6);
	}
});


$(window).load(function() {
	contentPopup.init();
});


var orientaionFix = {
	init: function() {
		this.setup();
	},
	setup: function() {
		function isMobileiOS() {
			
			if(navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i)) {
				return true;
			}
			
			return false;
		}
		
		function isMobileAndroid() {
			if(navigator.userAgent.match(/Android/i) && navigator.userAgent.match(/Mobile/i)) {
				return true;
			}
			
			return false;
		}
		
		function isAndroidTablet() {
			if(navigator.userAgent.match(/Android/i) && !navigator.userAgent.match(/Mobile/i)) {
				return true;
			}
			
			return false;
		}
		
		function isMobileSamsung() {
			if(navigator.userAgent.match(/Android/i) && navigator.userAgent.match(/Mobile/i) && navigator.userAgent.match(/SAMSUNG/i)) {
				return true;
			}
			
			return false;
		}
		
		
		
		
		function isIpad() {
			if(navigator.userAgent.match(/iPad/i)) {
				return false;
			}
			return false;
		}
		
		function isMobileOthers() {
			if(navigator.userAgent.match(/webOS/i)
				|| navigator.userAgent.match(/BlackBerry/i)
				|| navigator.userAgent.match(/Windows Phone/i)) {
					return true;
			}
			
			return false;
		}
		
		function isMobileDevice() {
			var isMobile = false;
			
			if(isMobileiOS()) {
				isMobile = true;
			}
			
			if(isMobileAndroid()) {
				isMobile = true;
			}
			
			if(isMobileOthers()) {
				isMobile = true;
			}
			
			if(isIpad()) {	
				isMobile = true;
			}

			//console.log(isIpad());

			return isMobile;
		}
	
		function checkOrientation(){
			var currMode = "";
			var orientation = ($(window).width() > $(window).height())? 90 : 0;//window.orientation;
			
			switch(orientation) {
				case 90: case -90:
				var currMode = 'landscape';
				break;
			default:
				var currMode = 'portrait';
				break;
			}
		
		if(isMobileDevice()) {
			if(currMode == "landscape") {
				$('#fp-nav').css('opacity', '0');
				$('#page').css('opacity', '0');
				$('#rotate-screen').css('display', 'block');
			} else {
					$('#rotate-screen').css('display', 'none');
					$('#page').css('opacity', '1');
					$('#fp-nav').css('opacity', '1');
				}
			} else {
				if(currMode == "portrait") {
					$('#rotate-screen').css('display', 'none');
				} else {
					$('#rotate-screen').css('display', 'none');
				}	
			}
		}
		
		if($("body").attr("data-article-id") == 1) {
			window.addEventListener('deviceorientation', function(eventData) {
				checkOrientation();
			});
			
			checkOrientation();
		}
	}
};

var getHelpers = {
	init: function() {
		this.relExternal();
		this.pageHeight();
	},
	relExternal: function() {
		$(document).on("click", "a[rel=external]", function(e) {
			window.open($(this).attr("href"), "_blank");
			e.preventDefault();
		});

		$(document).on("click", "a[class=ext]", function(e) {
			window.open($(this).attr("href"), "_blank");
			e.preventDefault();
		});
		
		
		$(document).on("click", "a[class=external]", function(e) {
			window.open($(this).attr("href"), "_blank");
			e.preventDefault();
		});
	},
	pageHeight: function() {
		if($("#page").height() > 1208) {
			if($('.last-article').length == 0) {
				$('.page-content').after().append("<div class='last-article'><a href='#' class='scroll-to-top icon icon-arrow-up'></a></div>");
			}
		} 
	}
};

var getSlideshow = {
	init: function() {
		if($(".swiper-container").length > 0) {
			var mySwiper = new Swiper('.swiper-container', {
				pagination: '.swiper-pagination',
				paginationClickable: true,
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				spaceBetween: 3
			});
		}	
	}
};

var getNavigation = {
	init: function() {
		$(".menu").on("click", "a", function(e) {
			e.preventDefault();
			
			$("#fp-nav").addClass("hide");
			$(".main-navigation").addClass("animate");
			
			var heightPage = $("body").height();
			$(".main-navigation.animate").height(heightPage);
			
			$('.nav-first-level li a').each(function( index ) {
				if($(this).attr('href') == '/%23s5') {
					$(this).attr('href', '#s5');
				}
			});
			
		});
		$(".close").on("click", function(e) {
			e.preventDefault();
			$(".main-navigation").removeClass("animate");
			setTimeout(function() {
				$("#fp-nav").removeClass("hide");
			}, 250)
		});
	}
};

var getHeaderColor = {
	init:function() {
		var lastScrollTop = 0;
				
		$(window).scroll(function(event) {
			var st = $(this).scrollTop();

			if(st < 450) {
				$('.header-image').css('display', 'block');
				$('.header-image').css({'background-position':'center calc(50% + '+(st*.1)+'px)'});
			} else {
				$('.header-image').css('display', 'none');
			}
		
			if(st > 60) {
				$("#page-header").addClass("scrolledDown");
			} else {
				$("#page-header").removeClass("scrolledDown");
			}

			lastScrollTop = st;
		});		
	}
};

var scrollToTop = {
	init:function() {
		var selector = ".scroll-to-top";
		$(selector).on("click", function(){
			$('html, body').animate({scrollTop : 0},800);
			return false;
		});
	}
};

var contentPopup = {
	init:function() {
		function HandleItemFormSubmitContact() {
			$('body').on('submit', '.contact-form #form_formular', function(e) {
				e.preventDefault();
				
				$('.contact-form .loading-icon').css({'display' : 'inline-block', 'opacity' : '1'});

				$.ajax({
					type: $(this).attr('method'),
					url: $(this).attr('action'),
					data: $(this).serialize(),
					success: function(data) {
						if($(data).find("ul.form_warning").length > 0) {
							
							$('#popup_contact').replaceWith($(data).get(0));
							
							if($("select", '#popup_contact').val() == "notselected") {
								$("select", '#popup_contact').addClass('error');
							} else {
								$("select", '#popup_contact').addClass('valid');
							}
							
									
							var lang = $("#page").attr("data-lang");
							var langtext = "";		
							
							if(lang == 0) {
								langtext = "Ihre Anfrage";
							} else {
								langtext = "Your Request";
							}
				
							$("#popup_contact select").addClass('required');
							$("#popup_contact textarea").attr("placeholder", langtext);
							
						} else {
							$('.contact-form').find('.form-thx-message').css('display', 'block').show();
							$('.contact-form').find(".form-wrapper").fadeOut();
							$('#popup_contact').animate({height: "160px"}, 350);
							$('.contact-form').find(".form-thx-message").fadeTo("slow", 1);
						}
					}
				});
			});
		}
		
		function HandleItemFormSubmitFreelancer() {
			$('body').on('submit', '.freelancer-form #form_formular', function(e) {
				e.preventDefault();
				
				
				$('.freelancer-form .loading-icon').css({'display' : 'inline-block', 'opacity' : '1'});
				
				$.ajax({
					type: $(this).attr('method'),
					url: $(this).attr('action'),
					data: $(this).serialize(),
					success: function(data) {
						if($(data).find("ul.form_warning").length > 0) {
							
							
							$('#popup_freelancer').replaceWith($(data).get(0));
							
							if($("select", '#popup_freelancer').val() == "notselected") {
								$("select", '#popup_freelancer').addClass('error');
							} else {
								$("select", '#popup_freelancer').addClass('valid');
							}
							
							var lang = $("#page").attr("data-lang");
							var langtext = "";		
							
							if(lang == 0) {
								langtext = "Ihre Anfrage";
							} else {
								langtext = "Your Request";
							}
				
							$("#popup_freelancer select").addClass('required');
							$("#popup_freelancer textarea").attr("placeholder", langtext);

							
						} else {
							$('.freelancer-form').find('.form-thx-message').css('display', 'block').show();
							$('.freelancer-form').find(".form-wrapper").fadeOut();
							$('#popup_freelancer').animate({height: "160px"}, 350);
							$('.freelancer-form').find(".form-thx-message").fadeTo("slow", 1);
						}
					}
				});
			});
		}
		
		function HandleItemFormSubmitNewsletter() {
			
			/*
$('body').on('submit', '.newsletter-suscribe-form', function(e) {
				e.preventDefault();


				$.ajax({
					type: $(this).attr('method'),
					url: $(this).attr('action'),
					data: $(this).serialize(),
					success: function(data) {
						
						console.log("$(this).attr('method')", $(this).attr('method'));
						console.log("$(this).attr('action')", $(this).attr('action'));
						console.log("data", data);
						
						if($(data).find("div.feedback").hasClass('error')) {
							

							if($("select", '#popup_newsletter').val() == "notselected") {
								$("select", '#popup_newsletter').addClass('error');
							} else {
								$("select", '#popup_newsletter').removeClass('error');
								$("select", '#popup_newsletter').addClass('valid');
							}
							
							$("#popup_newsletter input").each(function() {
								if(this.value == '') {
									$(this).addClass('error');
								} else {
									$(this).removeClass('error');
									$(this).addClass('valid');
								}
							});
							
							
							var errors = $(data).find("div.feedback");
							
							//console.log($(data));
							
							$("#mce-error-response").empty().append(errors);
							$("#mce-error-response").css("display", "block");

							
						} else {
							
							console.log('success');
							
							$("#mce-error-response").empty().css("display", "none");

							var lang = $("#page").attr("data-lang");
							var success = "";
							
							if(lang == 0) {
								success = '<div class="newsletterThxMsg"><p>Besten Dank für Ihre Newsletteranmeldung</p><p>Um die Anmeldung abzuschliessen, bestätigen Sie bitte das Mail, welches wir Ihnen eben zugestellt haben.</p></div>';
							} else {
								success = '<div class="newsletterThxMsg"><p>Thank you for your Newsletter Signup </p><p>To finish registration, please Confirm our send Mail.</p></div>';
							}			
							
							
							$('.newsletter-suscribe-form').find('.mc-field-group').fadeOut();
							$('.submit-button').fadeOut();
							
							$('.newsletter-suscribe-form').parent().animate({height: "150px"}, 500);
							$('.newsletter-suscribe-form').find("#mce-success-response").fadeTo("slow", 1);
							
							
							$("#mce-success-response").empty().append(success);
							$("#mce-success-response").css("display", "block");
						}
					}
				});

			});
*/
		}
		
		/***********************************************************************/
		$('.popup_contact').magnificPopup({
			type:'ajax',
			fixedContentPos: true,
			closeOnContentClick: false,
			closeOnBgClick: false,
			enableEscapeKey: false,
			
			callbacks: {
				beforeOpen: function() { 
					$.fn.fullpage.setAllowScrolling(false);
					
					$('html').addClass('mfp-helper');
					var lang = $("#page").attr("data-lang");
					var langtext = "";
					
					if(lang == 0) {
						langtext = "Ihre Anfrage";
					} else {
						langtext = "Your Request";
					}
					
										
					$("#popup_contact select").addClass('required');
					$("#popup_contact textarea").attr("placeholder", langtext);
				},
				ajaxContentAdded: HandleItemFormSubmitContact(),
				close: function() {
					$.fn.fullpage.setAllowScrolling(true);
					$('html').removeClass('mfp-helper');
				}
			}
		});
		
				
		/***********************************************************************/
		$('.popup_freelancer').magnificPopup({
			type:'ajax',
			fixedContentPos: true,
			closeOnContentClick: false,
			closeOnBgClick: false,
			enableEscapeKey: false,
			showCloseBtn: true,
			
			callbacks: {
				beforeOpen: function() {
					$('html').addClass('mfp-helper');
				},
				ajaxContentAdded: HandleItemFormSubmitFreelancer(),
				close: function() {
					$('html').removeClass('mfp-helper'); 
				}
			}			
		});

		
		/***********************************************************************/
		$('.popup_newsletter').magnificPopup({
			type:'ajax',
			fixedContentPos: true,
			
			callbacks: {
				beforeOpen: function() {
					$.fn.fullpage.setAllowScrolling(false);
					$('html').addClass('mfp-helper');
				},
				ajaxContentAdded: HandleItemFormSubmitNewsletter(),
				close: function() {
					$.fn.fullpage.setAllowScrolling(true);
					$('html').removeClass('mfp-helper'); 
				}
			}
		});
	}
};

var getLoadMorePHP = {
	init: function() {
		this.news();
		this.references();
	},
	news: function() {
		var id = $("body").attr("data-article-id"), 
			$news = 6,
			$newsArticles = $("#newsblock>.news");

			if(id == 2) {
				$news = 6;
			} else {
				if($(window).height() <= 750) {
					$news = 3;
				} else {
					$news = 5;
				}
			}
		
			$updateNews = function() {
			$vis = 0;
			$pos = 0;
			
			$newsArticles.each(function(ind,obj) {
				$pos++;

				$(this).css({"display":($pos>$news)?'none':'block'});
							 
				if($pos<=$news) { 
					$vis++; 
				}
			});
		
			if($vis == $newsArticles.length) {
				$("#btnMore").css({"display":"none"});
			}
		};
		
		$updateNews();
		
		$("#btnMore").on("click", function(e) {
			e.preventDefault();
			$news = $news + 4;
			$updateNews();
		});
	},
	references: function() {
		var $references = 10,
			$referenceArticles = $(".view-overview-small .link-wrapper .box-wrapper > a");
		

		$updateReferences = function() {
			$vis = 0;
			$pos = 0;
			
			$referenceArticles.each(function(ind,obj) {
				$pos++;

				$(this).css({"display":($pos>$references)?'none':'table'});
							 
				if($pos<=$references) { 
					$vis++; 
				}
			});
		
			if($vis == $referenceArticles.length) {
				$("#moreref").css({"display":"none"});
			}
		};
		
		$updateReferences();
		
		$("#moreref").on("click", function(e) {
			e.preventDefault();
			$references = $references + 4;
			$updateReferences();
		});
	}
};

var detailPageAnimation = {
	init: function() {
		this.news();
		this.services();
		this.references();
		this.aboutUs();
	},
	news: function() {
		$("#newsblock > .news").each(function(index) {
			var $el = $(this);
			
			setTimeout(function () {
				$el.addClass('visible');
			},200 * index);
		});
	},
	services: function() {
		$(".view-overview-big > .box-wrapper").each(function(index) {
			var $el = $(this);
			
			setTimeout(function () {
				$el.addClass('visible');
			},275 * index);
		});
	},
	references: function() {
		$(".view-overview-small .link-wrapper > .box-wrapper").each(function(index) {
			var $el = $(this);
			
			setTimeout(function () {
				$el.addClass('visible');
			},300 * index);
		});
	},
	aboutUs: function() {
		$(".view-overview-small .link-wrapper > .box-wrapper .team-item").each(function(index) {
			var $el = $(this);
			
			setTimeout(function () {
				$el.addClass('visible');
			},250 * index);
		});
	}
};

var slideShow = {
	init: function()
	{
		this.frontpage();
	},
	frontpage: function()
	{
		var pageTitle = ['Start', 'Dienstleistungen', 'Über uns', 'Referenzen', 'News', 'Kontakt'];

		if($("#page").attr("data-lang") != "0")
		{
			pageTitle = ['Start', 'Services', 'About us', 'References', 'News', 'Contact'];
		}

		var totalSlides = $('#fullpage > .section').length;

		var optionVerticalCenter = (document.documentElement.clientHeight >= 400) ? true : false;

		$('#fullpage').fullpage(
		{
			verticalCentered: optionVerticalCenter,
			sectionsColor: ['#4A4A4A', '#EEEEEE', '#4A4A4A', '#EEEEEE', '#FFFFFF', '#4A4A4A'],
			anchors: ['s0', 's1', 's2', 's3', 's4', 's5'],
			navigation: true,
			navigationPosition: 'right'
		});
	}
};

var backgroundMedia = {
    init: function()
	{
        this.run();
    },
	run: function()
	{
		$('.section, .slide').each(function()
		{
			if($(this).data('media'))
			{
				var file = $(this).data('media');
                var exte =  file.split('.').pop().toLowerCase();

				if($.inArray(exte, ['jpg','jpeg','png','svg','gif']) != -1)
				{
                    $(this).css('background-image', 'url(/files/' + file + ')');
                    $(this).removeAttr('data-media');
				}
                if($.inArray(exte, ['mp4','ogg','webm']) != -1)
				{
                    $(this).prepend('<video autoplay data-autoplay muted loop><source src="/files/' + file + '" type="video/' + exte + '"></video>');
                    $(this).removeAttr('data-media');
				}
			}
		});
    }
};